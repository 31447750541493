@mixin pseudoElIcon($name, $size: 24px, $important: false) {
    content: $name if($important, !important, ());
    font-family: 'Material Icons' if($important, !important, ());
    font-weight: normal if($important, !important, ());
    font-style: normal if($important, !important, ());
    font-size: $size if($important, !important, ());
    line-height: 1 if($important, !important, ());
    display: block if($important, !important, ());
    white-space: nowrap if($important, !important, ());
    -webkit-font-smoothing: antialiased if($important, !important, ());
}

@mixin createShadow($values) {
    box-shadow: 
        (nth($values, 1) + px) (nth($values, 2) + px) (nth($values, 3) + px) (nth($values, 4) + px) rgba(0, 0, 0, $shadow-key-umbra-opacity), 
        (nth($values, 5) + px) (nth($values, 6) + px) (nth($values, 7) + px) (nth($values, 8) + px) rgba(0, 0, 0, $shadow-key-penumbra-opacity),
        (nth($values, 9) + px) (nth($values, 10) + px) (nth($values, 11) + px) (nth($values, 12) + px) rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}

@mixin transform($args) {
    transform: $args;
}

@mixin no-textoverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Ref: https://www.sassmeister.com/gist/7f22e44ace49b5124eec
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }

    @media (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

@function strip-unit($number) {
    @if type-of($number) == "number" and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}
;@import "sass-embedded-legacy-load-done:2068";