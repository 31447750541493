@import "../../Assets/stylesheets/bootstrap/variables";

.flat-date-picker-component {
    $item-padding: .5rem;
    $button-color: $secondary;
    $button-color-hover: lighten($secondary, 10%);
    $border-color: $gray-200;
    $button-size: 2rem;
    $item-width: 1.5rem;
    $border-radius: .2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    font-size: .8rem;

    %flex-base {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .date-info {
        @extend %flex-base;
        font-weight: $font-weight-bold;
        margin-bottom: map-get($spacers, 1);
    }

    .h-bar {
        display: flex;
        align-items: stretch;
        flex-direction: row;

        %button-base {
            display: flex;
            width: $button-size;
            height: $button-size;
            flex: 0 0 $button-size;
            justify-content: center;
            align-items: center;
            align-self: center;
            border: .1rem solid $white;
            border-radius: 50%;
            cursor: pointer;
            user-select: none;
            text-decoration: none !important;
            @include transition;

            &:hover, &:focus {
                @include z-depth(5);
                background: $white;

                .icon {
                    color: $black;
                }
            }

            .icon {
                color: $white;
                @include transition(color $transition-duration-default $timing-func-deceleration);
            }
        }

        .goto-past-button {
            @extend %flex-base;
            @extend %button-base;
            margin-right: $item-padding;
        }

        .goto-future-button {
            @extend %flex-base;
            @extend %button-base;
            margin-left: $item-padding;
        }

        .timeline {
            display: flex;
            flex: 1;
            justify-content: space-around;
            align-items: center;
            max-width: 100%;
            overflow: hidden;

            .item-template {
                display: none;
            }

            .month {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                user-select: none;
                flex: 0 0 $item-width;

                .icon {
                    color: $white;
                    font-size: 1.3rem;
                }

                span {
                    font-size: .7rem;
                }
            }

            .day {
                display: flex;
                flex: 0 0 $item-width;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                border-radius: $border-radius;
                margin: 0 .1rem;
                cursor: pointer;
                user-select: none;
                @include transition;

                .day-label {
                    text-align: center;
                    border-bottom: .15rem solid $border-color;
                    @include transition(background $transition-duration-default $timing-func-deceleration);
                }

                .value {
                    color: $white;
                    text-align: center;
                    text-decoration: none;
                    @include transition(background $transition-duration-default $timing-func-deceleration);
                }

                &.day-friday,
                &.day-saturday {
                    font-weight: $font-weight-bold;

                    .day-label {
                        border-color: $primary;
                        background-image: linear-gradient(to top, rgba($primary, .4), rgba($primary, 0));
                    }
                }

                &.day-past {
                    opacity: $opacity-past-moment;
                }

                &.day-today {
                    border-color: $gray-500;
                }

                &:not(.disabled) {
                    &:hover,
                    &:focus,
                    &:active,
                    &.active {
                        background: $white;

                        .day-label {
                            color: $black;
                            border-bottom-color: $primary;
                            background-image: none;
                        }

                        .value {
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}
