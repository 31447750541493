@import "colors";

// Bootstrap
$enable-shadows: true;
$body-bg: $gray-50;
$body-color: $black;

//$font-family-sans-serif: 'Gotham Narrow A', 'Gotham Narrow B', sans-serif;
$font-family-sans-serif: 'Roboto', sans-serif;

$font-size-default: 16px;
$font-size-base: 0.875rem; // Uses $font-size-default as base
$font-size-big: 1.25rem;
$font-size-large: 1rem;
$font-size-smaller: 0.85rem;
$font-size-small: 0.75rem;
$font-size-tiny: 0.625rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700; // 600

$icon-size-sm: 18px;

$breakpoint-mobile: md;
$breakpoint-desktop: lg;

$link-color: $primary;

$border-radius: .2rem;

$opacity-past-moment: .6;
$enable-transitions: true;

$navbar-padding-y: .1rem;
$navbar-main-height: ($navbar-padding-y * 2) + (.3rem * 2) + 2rem;
$navbar-tabs-height: (.2rem * 2) + .8rem + .6rem + .4rem + .2rem;

$timing-func-standard: cubic-bezier(0.4, 0.0, 0.2, 1);
$timing-func-deceleration: cubic-bezier(0.0, 0.0, 0.2, 1);
$timing-func-acceleration: cubic-bezier(0.4, 0.0, 1, 1);
$timing-func-sharp: cubic-bezier(0.4, 0.0, 0.6, 1);

$transition-duration-default: .35s;
$transition-duration-fast: .2s;
$transition-duration-slow: .65s;

$transition-base: all $transition-duration-default $timing-func-deceleration;
$transition-collapse: height .35s $timing-func-deceleration;

// Z-depth and shadows
$shadow-key-umbra-opacity: .2;
$shadow-key-penumbra-opacity: .14;
$shadow-ambient-shadow-opacity: .12;

$badge-font-size: $font-size-small;
$badge-font-weight: $font-weight-medium;

// Button
$btn-border-width: 0.1rem;

// Forms
$input-bg: get-color('grey', '100');
$input-border-width: 0;

// Footer
$footer-floating-btn-spacing: 15px;

// Import bootstrap files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "functions";
@import "mixins";
@import "z-depth";

// Custom variables requiring bootstrap's variables
$component-spacing-default: map-get($spacers, 3);

;@import "sass-embedded-legacy-load-done:31";